import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/garantias'
				,name: 'administrador_de_garantias'
				,component: () => import('@/apps/garantias/Dashboard')
				,meta: {
					title: 'Administración de garantias'
					,middleware: auth
				}
			},
			{
				path: '/garantias/fideicomisos/:tipo?'
				,name: 'garantias_fideicomisos_fideicomisos'
				,component: () => import('@/apps/garantias/pages/Fideicomisos/Fideicomiso')
				,meta: {
					title: 'Fideicomisos - Administración de garantias'
					,middleware: auth
				}
			},
			{
				path: '/garantias/fideicomisos/:tipo?'
				,name: 'garantias_fideicomisos_fideicomisos_convenios'
				,component: () => import('@/apps/garantias/pages/Fideicomisos/Fideicomiso')
				,meta: {
					title: 'Convenios - Administración de garantias'
					,middleware: auth
				}
			},
			{
				path: '/garantias/patrimonios'
				,name: 'garantias_fideicomisos_patrimonios'
				,component: () => import('@/apps/garantias/pages/Fideicomisos/Patrimonio')
				,meta: {
					title: 'Patrimonios - Administración de garantias'
					,middleware: auth
				}
			},
			{
				path: '/garantias/clientes'
				,name: 'garantias_fideicomisos_clientes'
				,component: () => import('@/apps/garantias/pages/Fideicomisos/Cliente')
				,meta: {
					title: 'Clientes - Administración de garantias'
					,middleware: auth
				}
			},
			{
				path: '/activos/garantias'
				,name: 'garantias_activos_garantias'
				,component: () => import('@/apps/garantias/pages/Activos/Garantia')
				,meta: {
					title: 'Garantias para venta - Activos no productivos - Administrador de garantias'
					,middleware: auth
				}
			},
			{
				path: '/otros/catalogos'
				,name: 'garantias_otros_catalogos'
				,component: () => import('@/apps/garantias/pages/Otros/Catalogo')
				,meta: {
					title: 'Catálogos - Otros - Administrador de garantias'
					,middleware: auth
				}
			},
			{
				path: '/garantias/mensajes'
				,name: 'garantias_mensajes'
				,component: () => import('@/apps/garantias/pages/Activos/Mensaje')
				,meta: {
					title: 'Mensajes - Garantias - Administrador de garantias'
					,middleware: auth
				}
			},
			{
				path: '/garantias/layout'
				,name: 'garantias_layout'
				,component: () => import('@/apps/garantias/pages/Otros/Layout')
				,meta: {
					title: 'Layout - Otros - Administrador de garantias'
					,middleware: auth
				}
			}
		]
	}
]
